<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-card-title class="d-flex align-item-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$router.back()"
        >
          <feather-icon
            class="mr-25"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <h2 class="ml-1 mb-0">
          {{ applicants.title }}
        </h2>
      </b-card-title>
      <section class="grid-view">
        <b-card
          v-for="(application, i) in applications"
          :key="i"
          :title="application.title"
          class="ecommerce-card"
          style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;"
          img-alt="card img"
          img-top
          no-body
        >
          <b-card-body>
            <b-card-text>
              <b-card-title class="mb-1">
                <b-badge
                  variant="primary"
                  size="lg"
                  class="mr-25"
                >
                  Round {{ applications.length - i }}
                </b-badge>
                {{ application.title }}<br>
                <small v-if="application.status !== 'enabled'">
                  <b-badge
                    class="text-capitalize"
                    small
                  > Applications
                    {{ application.status }}
                  </b-badge>
                </small>
              </b-card-title>
              <hr>
              <p
                class="h5"
              >Status:
                <b-badge
                  v-if="application.programs_applicantprogresstables[0]"
                  pill
                  :variant="application.programs_applicantprogresstables[0].status === 'Promoted' ? 'success' : ''"
                  class="text-capitalize"
                  size="lg"
                  v-text="application.programs_applicantprogresstables[0].status"
                />
              </p>

              <p><span class="h5">Recommendation: </span><br><em v-if="application.programs_applicantprogresstables[0]">{{
                application.programs_applicantprogresstables[0].recommendation
              }}</em></p>
            </b-card-text>
          </b-card-body>
          <!-- Product Actions -->
          <div class="item-options text-center">
            <b-link
              v-if="application.review_status!=='enabled'"
              :disabled="!selectStartup.id"
              :to="{name: 'startup-view-evaluation', params: {pid: $route.params.id, apid: application.id, aid: selectStartup.id}}"
              class="btn btn-success btn-cart"
            >
              <span>Report</span>
            </b-link>
            <b-link
              :disabled="!selectStartup.id"
              :to="{ name: 'startup-application-question', params: { pid: $route.params.id, apid: application.id, aid: selectStartup.organization_id_id }}"
              class="btn btn-primary btn-cart"
            >
              <span>Open</span>
            </b-link>
          </div>
        </b-card>
      </section>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BBadge, BButton, BCard, BCardBody, BCardText, BCardTitle, BLink, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/utils/auth'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BBadge,
    BOverlay,
    BLink,
  },
  data() {
    return {
      selectStartup: {
        id: null,
        current_application_form_id: null,
        organization_id_id: null,
      },
      applicants: [],
      applications: [],
      userOrgs: getUserData()
        .associatedOrgDetails
        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  apollo: {
    applicants: {
      query() {
        return gql`
      query applicants {
        programs_basicinfo_by_pk(id: ${this.$route.params.id}) {
          id
          title
          programs_applicantstables(where: {organization_id_id: {_in: [${this.userOrgs}]}}) {
            current_application_form_id
            organization_id_id
            id
            users_organizationtable {
              title
            }
          }
        }
      }`
      },
      update(data) {
        this.selectStartup = data.programs_basicinfo_by_pk.programs_applicantstables.find(e => e.id === Number.parseInt(this.$route.params.sid, 10))
        // eslint-disable-next-line no-return-assign,no-param-reassign
        data.programs_basicinfo_by_pk.programs_applicantstables.forEach(x => x.org = x.users_organizationtable.title)
        return data.programs_basicinfo_by_pk
      },
    },
    applications: {
      query() {
        return gql`
       query applications {
        programs_applicationformstable(order_by: {id: desc}, where: {program_id: {_eq: ${this.$route.params.id}}, status: {_neq: "draft"}, id: {_lte: ${this.$route.params.aid}}}) @include(if: ${!!this.$route.params.sid}) {
            id
            round_number
            title
            status
            review_status
            programs_applicantprogresstables(where: {applicant_id_id: {_eq: ${this.$route.params.sid} }}) {
              status
              recommendation
            }
          }
        }`
      },
      update: data => data.programs_applicationformstable,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
